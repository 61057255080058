import Login from "./components/Login/Login.jsx";
import SafetyRuleSignList from "./components/SafetyRuleSignList/SafetyRuleSignList.jsx";
import WorkerListPDF from "./components/WorkerListPDF/WorkerListPDF.jsx";

const rootPath = process.env.PUBLIC_URL;
console.log("rootPath");
console.log(rootPath);

const routes = [
  {
    path: `/`,
    component: Login,
    exact: true,
  },

  {
    path: `/safety-rules-sign-list`,
    component: SafetyRuleSignList,
  },

  {
    path: `/login`,
    component: Login,
  },

  {
    path: `/pdf`,
    component: WorkerListPDF,
  },
];

export default routes;
export { rootPath };
