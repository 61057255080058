const TaiwanCity = [
  { name: "全部縣市", value: "%" },
  { name: "基隆", value: "基隆" },
  { name: "台北", value: "台北" },
  { name: "新北", value: "新北" },
  { name: "桃園", value: "桃園" },
  { name: "新竹", value: "新竹" },
  { name: "苗栗", value: "苗栗" },
  { name: "台中", value: "台中" },
  { name: "彰化", value: "彰化" },
  { name: "南投", value: "南投" },
  { name: "雲林", value: "雲林" },
  { name: "嘉義", value: "嘉義" },
  { name: "台南", value: "台南" },
  { name: "高雄", value: "高雄" },
  { name: "屏東", value: "屏東" },
  { name: "宜蘭", value: "宜蘭" },
  { name: "花蓮", value: "花蓮" },
  { name: "台東", value: "台東" },
  { name: "澎湖", value: "澎湖" },
  { name: "金門", value: "金門" },
  { name: "連江", value: "連江" },
];

export { TaiwanCity };
