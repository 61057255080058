import React, { useContext, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import { getEmpInfoByLineId, initDB } from "../../Services/APIService";

import loginBg from "../../assets/images/login_bg.jpg";

import { message } from "antd";

import liffHelper from "../../Utils/liffHelper";
import { encodeStr, decodeStr } from "../../Utils/Encypt";

import { QRCode } from "react-qrcode-logo";
import cookie from "react-cookies";

import * as _ from "lodash";
import * as moment from "moment";
import "./Login.scss";

export default function Login(props) {
  const { setUpdateTime, setEmpProfile } = useContext(AppContext);

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    async function fetchData() {
      // INIT DB
      await initDB();

      // 取得 Line 使用者資訊
      let profile = {};

      if (process.env.REACT_APP_ENV === "dev") {
        //開發環境
        /**************************** 測試用 ****************************/
        profile = {
          displayName: "Open Open",
          pictureUrl:
            "https://profile.line-scdn.net/0m01cf17e672515d1250f469b9c4e76d6f06fafca5a0a7",
          statusMessage: "(alien face)",
          userId: "U0c95c20797c6deca8da74d790ad7cc87",
        };
        /**************************** 測試用 ****************************/
      } else {
        //正式環境,測試環境
        profile = await liffHelper.getUserProfile();
      }

      //檢查是否已綁定
      const checlkBindedResult = await getEmpInfoByLineId({
        lineId: profile.userId,
      });

      if (_.get(checlkBindedResult, "RESULT") === "OK") {
        //已綁定
        message.success("登入成功!");

        const empData = JSON.parse(_.get(checlkBindedResult, "DATA"));

        // 塞 local storage
        const obj = {
          date: moment().format("YYYY-MM-DD"),
          ...empData,
        };
        localStorage.setItem("CIRSOLAR_TOKEN", encodeStr(JSON.stringify(obj)));
        // cookie.save("CIRSOLAR_TOKEN ", encodeStr(JSON.stringify(obj)), {
        //   domain: ".cirsolar.cloud",
        // });

        //更新員工資料
        setEmpProfile({ ...empData, ...profile });

        //更新 update time
        setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

        // 轉跳 main menu page
        props.history.push("/safety-rules-sign-list");

        return;
      }

      message.success(`${_.get(profile, "displayName")} 請綁定工號!`);
    }

    // cirsolar TOKEN
    const cirsolarTokenStr = localStorage.getItem("CIRSOLAR_TOKEN");
    // const cirsolarTokenStr = cookie.load("CIRSOLAR_TOKEN");

    if (_.size(cirsolarTokenStr) === 0) {
      //沒有 TOKEN
      // do fetchData (取得 Line 使用者資訊)
      fetchData();
    } else {
      // 有 TOKEN 檢查登入日期
      const cirsolarTokenObj = JSON.parse(decodeStr(cirsolarTokenStr));

      if (
        _.size(cirsolarTokenObj) > 0 &&
        _.get(cirsolarTokenObj, "date") === moment().format("YYYY-MM-DD")
      ) {
        // 直接登入
        setEmpProfile(cirsolarTokenObj);

        //更新 update time
        setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));

        // 轉跳 main menu page
        props.history.push("/safety-rules-sign-list");
      } else {
        // do fetchData (取得 Line 使用者資訊)
        fetchData();
      }
    }

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  return (
    <div className="login-comp">
      {/* 背景圖檔 */}
      <div className="bg-image">
        {" "}
        <img src={loginBg} alt="" />
      </div>

      {/* CIRSOLAR */}
      <div className="cir-area">
        <h1>CIRSOLAR</h1>
      </div>

      {/* QR Code Area */}
      <div className="qr-code-area">
        <QRCode
          value={process.env.REACT_APP_LINE_AT_LINK}
          eyeRadius={1}
          fgColor="#36cfc9"
        />
      </div>

      {/* 提醒訊息 */}
      <div className="notice-message-area">綁定工號後重新整理登入使用</div>
    </div>
  );
}
