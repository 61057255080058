import * as axios from "axios";

/**
 *
 * APIURL
 *
 *
 */
const getAPIURL = () => {
  return `${process.env.REACT_APP_API}`;
};

/**
 *
 * AUTH APIURL
 *
 *
 */
const getAuthAPIURL = () => {
  return `${process.env.REACT_APP_AUTH_API}`;
};

/**
 *
 * 工單案場人數清單 by 日期,案場名稱,管理師名稱
 *
 */
const workOrderSiteCountByDate = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/toolbox-safety-meeting/count/worksite`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得已簽人員資料 by site id & date
 *
 */
const selectSignedWorkerListBySiteIdAndCreatDate = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/toolbox-safety-meeting/list/signedworkers`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 刪除簽名資料 by 頭像相片link
 *
 */
const deleteSignResultByImageLink = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/toolbox-safety-meeting/detail/delete/safetyrule/sign`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * 取得員工資料 by Line Id
 *
 */
const getEmpInfoByLineId = async (_obj) => {
  const api = getAPIURL();
  const URL = `${api}/api/get/emp/bylineid`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

/**
 *
 * INIT DB
 *
 */
const initDB = async () => {
  const api = getAPIURL();
  const URL = `${api}/api/init/DB`;

  try {
    const response = await axios.get(URL);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};


/**
 *
 *  新增 稽核軌跡紀錄
 *
 */
const addAuditTrail = async (_obj) => {
  const api = getAuthAPIURL();
  const URL = `${api}/api/add/audit-trail`;

  try {
    const response = await axios.post(URL, _obj);

    return response.data;
  } catch (err) {
    console.log(err);

    return err;
  }
};

export {
  workOrderSiteCountByDate,
  selectSignedWorkerListBySiteIdAndCreatDate,
  deleteSignResultByImageLink,
  getEmpInfoByLineId,
  initDB,
  addAuditTrail
};
