import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import {
  workOrderSiteCountByDate,
  selectSignedWorkerListBySiteIdAndCreatDate,
  deleteSignResultByImageLink,
  addAuditTrail,
} from "../../Services/APIService";
import { decodeStr } from "../../Utils/Encypt";

import cirsolarLogoDark from "../../assets/images/cirsolar-logo-dark.png";
import safetyRulesBg from "../../assets/images/safety_rules_bg.jpg";

import {
  Input,
  Table,
  DatePicker,
  Button,
  Progress,
  Skeleton,
  Drawer,
  message,
  Select,
  Popconfirm,
} from "antd";

import {
  SearchOutlined,
  ThunderboltFilled,
  CheckCircleFilled,
  PrinterOutlined,
} from "@ant-design/icons";

import * as _ from "lodash";
import CryptoJS from "crypto-js";
import moment from "moment";

import { TaiwanCity } from "../../Utils/TaiwanCity";
import "./SafetyRuleSignList.scss";

const { Option } = Select;

export default function SafetyRuleSignList(props) {
  const { updateTime, setUpdateTime, setEmpProfile, empProfile } =
    useContext(AppContext);

  const [qDate, setQDate] = useState(moment()); //query 日期

  const [qSiteName, setQSiteName] = useState("%"); //query 案場名稱

  const [qCity, setQCity] = useState("%"); //query 案場縣市

  const [qSiteMgr, setQSiteMgr] = useState("%"); //query 監工名稱

  const [dataSource, setDataSource] = useState([]); //案場人數資料清單

  const [isTableDataLoading, setIsTableDataLoading] = useState(false); //  表格資料下載loading

  const [isShowDrawer, setIsShowDrawer] = useState(false); // 抽屜面板顯示

  const [signedWorkerList, setSignedWorkerList] = useState([]); // 已簽人員清單

  const [rowClickRecord, setRowClickRecord] = useState({}); // 列點擊資料

  const columns = [
    {
      title: "",
      dataIndex: "eeee",
      key: "eeee",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "施工日期",
      dataIndex: "dateCreate",
      key: "dateCreate",
      render: (text, record, index) => {
        return moment(record.dateCreate).format("YYYY-MM-DD");
      },
    },
    {
      title: "案場ID",
      dataIndex: "siteId",
      key: "siteId",
    },

    {
      title: "案場名稱",
      dataIndex: "siteName",
      key: "siteName",
    },
    {
      title: "案場縣市",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "案場管理師人數",
      dataIndex: "siteMgrCount",
      key: "siteMgrCount",
      align: "right",
      width: "158px",
      render: (text, record, index) => {
        if (record.totalCount > 0) {
          return <span>{text}</span>;
        } else {
          return <span>---</span>;
        }
      },
    },
    {
      title: "施工人數",
      dataIndex: "workersCount",
      key: "workersCount",
      align: "right",
      width: "158px",
      render: (text, record, index) => {
        if (record.totalCount > 0) {
          return <span>{text}</span>;
        } else {
          return <span>---</span>;
        }
      },
    },
    {
      title: "總人數",
      dataIndex: "totalCount",
      key: "totalCount",
      align: "right",
      width: "158px",
      render: (text, record, index) => {
        if (record.totalCount > 0) {
          return <span>{text}</span>;
        } else {
          return <span>---</span>;
        }
      },
    },
    {
      title: "已簽人數",
      dataIndex: "signCount",
      key: "signCount",
      align: "right",
      width: "158px",
      render: (text, record, index) => {
        if (record.totalCount > 0) {
          return <span>{text}</span>;
        } else {
          return <span>---</span>;
        }
      },
    },
    {
      title: "尚欠人數",
      dataIndex: "diffCount",
      key: "diffCount",
      align: "right",
      width: "158px",

      render: (text, record, index) => {
        if (record.totalCount > 0) {
          if (_.toNumber(text) < 0) {
            return <span class="red-text">{text}</span>;
          } else {
            return <span class="green-text">{text}</span>;
          }
        } else {
          return <span>---</span>;
        }
      },
    },
    {
      title: "百分比",
      dataIndex: "progressbar",
      key: "progressbar",
      render: (text, record, index) => {
        const percent =
          record.totalCount > 0
            ? (record.signCount / record.totalCount) * 100
            : 0;
        return <Progress percent={_.round(percent)} />;
      },
    },
  ];

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // componentDidMount is here!
    // 驗證登入 cirsolar TOKEN
    verifyCirsolatToken();

    return () => {
      // componentWillUnmount is here!
    };
  }, []);

  /**
   *
   *
   * 驗證登入 cirsolar TOEKN
   *
   *
   */
  const verifyCirsolatToken = () => {
    // cirsolar TOKEN
    const cirsolarTokenStr = localStorage.getItem("CIRSOLAR_TOKEN");
    if (_.size(cirsolarTokenStr) === 0) {
      //沒有 TOKEN => 返回 login page
      // 轉跳  page
      props.history.push("/login");
    } else {
      // 有 TOKEN 檢查登入日期
      const cirsolarTokenObj = JSON.parse(decodeStr(cirsolarTokenStr));

      if (
        _.size(cirsolarTokenObj) > 0 &&
        _.get(cirsolarTokenObj, "date") === moment().format("YYYY-MM-DD")
      ) {
        // 直接登入
        setEmpProfile(cirsolarTokenObj);

        //更新 update time
        setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss") + "GOODTOGO");
      } else {
        // do fetchData (取得 Line 使用者資訊)
        // 轉跳  page
        props.history.push("/login");
      }
    }
  };

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // fetchData function
    async function fetchData() {
      const obj = {
        qDate: qDate.format("YYYY-MM-DD"),
        qSiteName: _.size(qSiteName) === 0 ? "%" : `%${qSiteName}%`,
        qCity: _.size(qCity) === 0 ? "%" : `%${qCity}%`,
        qSiteMgr: _.size(qSiteMgr) === 0 ? "%" : `%${qSiteMgr}%`,
      };

      //取得工單案場人數清單 by 日期,案場名稱,管理師名稱
      const workOrderSiteCountList = await workOrderSiteCountByDate(obj);

      setDataSource(workOrderSiteCountList);
    }

    // do fetchData
    setIsTableDataLoading(true);
    fetchData();
    setIsTableDataLoading(false);

    return () => {
      // componentWillUnmount is here!
    };
  }, [updateTime]);

  const onSearch = () => {
    //稽核軌跡 搜尋
    const searchObj = {
      porgName: "勞安檢查",
      page: "勞安檢查",
      action: "search",
      empId: empProfile.empId,
      userCreate: empProfile.empId,
      searchRange: JSON.stringify({
        日期: qDate.format("YYYY-MM-DD"),
        案場名稱:
          _.size(qSiteName) === 0
            ? undefined
            : qSiteName === "%"
            ? undefined
            : qSiteName,
        縣市:
          _.size(qCity) === 0 ? undefined : qCity === "%" ? undefined : qCity,
        案場管理師:
          _.size(qSiteMgr) === 0
            ? undefined
            : qSiteMgr === "%"
            ? undefined
            : qSiteMgr,
      }),
    };
    addAuditTrail(searchObj);

    setDataSource([]);
    setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));
  };

  /**
   *
   * 抽屜面板關閉
   *
   *
   */
  const onDrawerClose = () => {
    setIsShowDrawer(false);
  };

  /**
   *
   * 表格列點擊 Event
   *
   *
   */
  const onRowClick = async (_record) => {
    //設定列點擊資料
    setRowClickRecord(_record);

    //取得已簽人員資料 by site id & date
    const obj = {
      qDate: moment(_record.dateCreate).format("YYYY-MM-DD"),
      qSiteId: _record.siteId,
    };

    const signedWorkerListRes =
      await selectSignedWorkerListBySiteIdAndCreatDate(obj);

    setSignedWorkerList(signedWorkerListRes);

    //打開抽屜面板
    setIsShowDrawer(true);
  };

  /**
   *
   *
   * 登出按鈕
   *
   */
  const logout = () => {
    localStorage.removeItem("CIRSOLAR_TOKEN");
    localStorage.clear();
    // 轉跳  page
    props.history.push("/login");
  };

  /**
   *
   * 前往列印頁
   *
   *
   */
  const gotoPrintPage = () => {
    props.history.push({
      pathname: "/pdf",
      state: { data: dataSource },
    });
    // Default export is a4 paper, portrait, using millimeters for units
    // const doc = new jsPDF();

    // doc.text("Hello world!", 10, 10);
    // doc.save("a4.pdf");

    // print("a", "jsx-template");
    // window.print();
  };

  /**
   *
   *
   * 刪除簽名資料 by 頭像相片link
   *
   *
   */
  const doDeleteSignResultByImageLink = async (_model) => {
    const deleteSignResultByImageLinkRes = await deleteSignResultByImageLink(
      _model
    );
    if (_.get(deleteSignResultByImageLinkRes, "RESULT") === "OK") {
      // 刪除成功
      message.success("刪除成功!");
      setUpdateTime(moment().format("YYYY-MM-DD HH:mm:ss.sss"));
      onRowClick(rowClickRecord);
    }
  };

  /* ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ JSX ◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤ */

  return (
    <div className="safety-rules-sign-list-comp">
      {/* nav bar */}
      <div className="nav-bar-area float-shadow">
        <div className="cir-logo-area">
          <img src={cirsolarLogoDark} alt="" />
        </div>
        <div className="btn-area">
          <Button shape="round">
            {process.env.REACT_APP_ENV}:{process.env.REACT_APP_VER}
          </Button>

          <Button
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
            onClick={() => gotoPrintPage()}
          >
            前往列印頁
          </Button>
          <Button shape="round" danger onClick={() => logout()}>
            登出
          </Button>
        </div>
      </div>
      {/* bg */}
      <div className="safety-rules-bg-area">
        <img src={safetyRulesBg} alt="" />
        <div className="title">
          <h1>工具箱會議</h1>
        </div>
      </div>
      {/* search bar  */}
      <div className="search-bar-area">
        <div className="search-bar-container">
          {/* 施工日期 */}
          <div className="search-item">
            <DatePicker
              bordered={false}
              placeholder="• 請選擇施工日期"
              defaultValue={qDate}
              onChange={(e) => setQDate(e)}
            />
          </div>

          {/* 案場名稱 */}
          <div className="search-item">
            <Input
              placeholder="• 案場名稱"
              bordered={false}
              onChange={(e) => setQSiteName(e.target.value)}
              allowClear={true}
            />
          </div>

          {/* 案場縣市 */}
          <div className="search-item">
            <Select
              defaultValue={qCity}
              bordered={false}
              onChange={(e) => setQCity(e)}
            >
              {TaiwanCity.map((item, index) => {
                return (
                  <Option value={item.value} key={`tcity-${index}`}>
                    • {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>

          {/* 案場管理師名稱 */}
          <div className="search-item">
            <Input
              placeholder="• 案場管理師名稱"
              bordered={false}
              onChange={(e) => setQSiteMgr(e.target.value)}
              allowClear={true}
            />
          </div>

          {/* 搜尋按鈕 */}
          <div className="search-item btn">
            <Button
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              onClick={() => onSearch()}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      {/* 骨架屏 */}
      {isTableDataLoading && <Skeleton active className="skeleton-area" />}

      {/* 表格*/}
      {!isTableDataLoading && (
        <div className="table-area">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: (event) => onRowClick(record),
              };
            }}
          />
        </div>
      )}

      {/* 抽屜面板 */}
      <Drawer
        className="drawer-area"
        title={`${moment(rowClickRecord.dateCreate).format("YYYY-MM-DD")} - ${
          rowClickRecord.siteName
        }`}
        placement="right"
        onClose={onDrawerClose}
        visible={isShowDrawer}
        width="380px"
      >
        {/* 已簽人員清單 */}
        <div className="signed-worker-list-area">
          {signedWorkerList.map((item, index) => {
            return (
              <div className="signed-worker-item">
                {/* 上  - 簽名圖檔*/}
                <div className="signed-worker-item-upper">
                  <img src={item.signatureImageLink} alt="" />
                </div>

                {/* 下  - 資料區*/}
                <div className="signed-worker-item-down">
                  {/* 左邊 頭像區 */}
                  <div className="left">
                    <div className="worker-avatar clip-circle">
                      <img src={item.workerImageLink} alt="" />
                    </div>
                  </div>

                  {/* 中間 姓名 時間區 */}
                  <div className="middle">
                    <div className="worker-name">
                      <h3>
                        {item.flagSiteMgr === "Y" && (
                          <ThunderboltFilled className="goldStar" />
                        )}
                        {index + 1}. {item.workerName}
                      </h3>
                    </div>

                    <div className="worker-id">
                      {" "}
                      <h4>{item.workerId}</h4>
                    </div>
                    <div className="date-create">
                      {moment(item.dateCreate).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </div>

                  {/* 右邊 綠色勾勾   */}
                  <div className="right">
                    <Popconfirm
                      title="確認刪除?"
                      onConfirm={() => doDeleteSignResultByImageLink(item)}
                      okText="確認"
                      cancelText="取消"
                    >
                      <CheckCircleFilled />
                    </Popconfirm>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
}
