import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../Context/AppContext";
import cirsolarLogoDark from "../../assets/images/cirsolar-logo-dark.png";

import { Table, Tag, Space, Image, Button } from "antd";
import { PrinterOutlined, RollbackOutlined } from "@ant-design/icons";

import * as _ from "lodash";
import moment from "moment";
import { addAuditTrail } from "../../Services/APIService";
import "./WorkerListPDF.scss";

export default function WorkerListPDF(props) {
  const [dataSource, setDataSource] = useState([]); //案場人數資料清單
  const { empProfile } = useContext(AppContext);
  const columns = [
    {
      title: "日期",
      dataIndex: "date",
      key: "date",
      render: (text, record, index) => {
        return moment(record.dateCreate).format("YYYY-MM-DD");
      },
    },
    {
      title: "時間",
      dataIndex: "time",
      key: "time",
      render: (text, record, index) => {
        return moment(record.dateCreate).format("HH:mm:ss");
      },
    },
    {
      title: "類型",
      dataIndex: "type",
      key: "type",
      render: (text, record, index) => {
        return record.flagSiteMgr === "Y" ? "案場管理師" : "施工人員";
      },
    },
    {
      title: "人員名稱",
      dataIndex: "workerName",
      key: "workerName",
    },
    {
      title: "相片",
      dataIndex: "workerImageLink",
      key: "workerImageLink",
      render: (text, record, index) => {
        return (
          <div className="image-container">
            <Image width={80} src={record.workerImageLink} alt="" />
          </div>
        );
      },
    },
    {
      title: "簽名",
      dataIndex: "signatureImageLink",
      key: "signatureImageLink",
      render: (text, record, index) => {
        return (
          <div className="image-container">
            <Image width={100} src={record.signatureImageLink} alt="" />
          </div>
        );
      },
    },
  ];

  /**
   *
   * componentDidMount
   *
   *
   */
  useEffect(() => {
    // setDataSource(
    //   JSON.parse(
    //     '[{"key":"0b68db42-ac2e-4e6a-a8db-42ac2e9e6abb","siteId":"55555","siteName":"義竹地面型","city":"嘉義縣","workersCount":2,"siteMgrCount":1,"totalCount":3,"signCount":3,"diffCount":"0","dateCreate":"Nov 2, 2021 12:00:00 AM","workerList":[{"key":"182e2e2e-2bb8-4d36-ae2e-2e2bb88d36ca","siteId":"55555","workerName":"龜仙人","workerId":"0909222222","workerImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/97e41af9-be46-4ecd-a41a-f9be46decd80_2021-11-02_08%3A49%3A53.jpeg","signatureImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/0a9f3ae7-431b-4077-9f3a-e7431b407755_2021-11-02_08%3A49%3A53.png","flagReaded":"Y","flagSiteMgr":"Y","dateCreate":"Nov 2, 2021 8:49:53 AM"},{"key":"87997b40-a322-4f16-997b-40a322af16fa","siteId":"55555","workerName":"蟹老闆","workerId":"0909111111","workerImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/33889010-50ea-4c92-8890-1050ea3c9256_2021-11-02_08%3A49%3A15.png","signatureImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/c52c2575-fb59-4fa5-ac25-75fb593fa59a_2021-11-02_08%3A49%3A16.png","flagReaded":"Y","flagSiteMgr":"N","dateCreate":"Nov 2, 2021 8:49:16 AM"},{"key":"cce5249f-f30a-4b86-a524-9ff30a6b869c","siteId":"55555","workerName":"佩佩豬","workerId":"0909333333","workerImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/c65b1068-9fbd-49bc-9b10-689fbdc9bce8_2021-11-02_08%3A50%3A43.png","signatureImageLink":"https://storage.googleapis.com/tst-cirsolar-toolbox-safety-meeting/bc8abedc-e2df-476e-8abe-dce2dfe76ed9_2021-11-02_08%3A50%3A43.png","flagReaded":"Y","flagSiteMgr":"N","dateCreate":"Nov 2, 2021 8:50:43 AM"}]},{"key":"7212ff5e-ded3-413d-92ff-5eded3113d5b","siteId":"666666","siteName":"測試","city":"台北市","workersCount":0,"siteMgrCount":0,"totalCount":0,"signCount":0,"diffCount":"0","dateCreate":"Nov 2, 2021 12:00:00 AM","workerList":[]}]'
    //   )
    // );
    if (_.get(props.history.location.state, "data") === undefined) {
      setDataSource([]);
    } else {
      setDataSource(props.history.location.state.data);
    }
    // window.print();
  }, []);

  /**
   *
   * 列印
   *
   *
   */
  const onPrint = () => {
    window.print();
    //稽核軌跡 列印
    const searchObj = {
      porgName: "勞安檢查",
      page: "列印頁",
      action: "print",
      empId: empProfile.empId,
      userCreate: empProfile.empId,
    };
    addAuditTrail(searchObj);
  };

  /**
   *
   * 返回
   *
   */
  const goBack = () => {
    props.history.goBack();
  };
  return (
    <div>
      {/* 無資料 */}
      {_.size(dataSource) === 0 && <p>無資料</p>}

      {/* 有資料 */}
      {_.size(dataSource) > 0 && (
        <div className="worker-list-pdf-area">
          {/* 列印按鈕 */}
          <div className="print-btn">
            <Button
              type="primary"
              shape="round"
              icon={<RollbackOutlined />}
              onClick={() => goBack()}
            >
              返回
            </Button>
            <Button
              type="primary"
              shape="round"
              icon={<PrinterOutlined />}
              onClick={() => onPrint()}
            >
              列印
            </Button>
          </div>

          {/* logo */}
          <div className="logo">
            <img src={cirsolarLogoDark} alt="" />
          </div>

          {/* title */}
          <div className="title">
            <h2>工具箱會議參與簽名人員清單</h2>
            <h3>{moment(dataSource[0]["dateCreate"]).format("YYYY/MM/DD")} </h3>
          </div>

          {/* data */}
          {dataSource.map((item, index) => {
            return (
              <div className="item">
                {/* 案場名稱 */}
                <h2 className="site-name">
                  {item.siteName} - {item.city}
                </h2>

                {/* 人數資訊 */}
                {/* <div className="info">
                  <div>案場管理師: {item.siteMgrCount} 人</div>
                  <div>施工人員: {item.workersCount} 人</div>
                </div>

                <div className="info">
                  <div>總人數: {item.totalCount} 人</div>
                  <div>簽名人數: {item.signCount}人 </div>
                  <div>
                    完成率: {_.round(item.signCount / item.totalCount, 2) * 100}
                    %{" "}
                  </div>
                </div> */}
                <div className="info-table">
                  <table>
                    <tr>
                      <td>案場管理師:</td>
                      <td>{item.siteMgrCount} 人</td>
                      <td>施工人員:</td>
                      <td>{item.workersCount} 人</td>
                    </tr>
                    <tr>
                      <td>總人數:</td>
                      <td>{item.totalCount} 人</td>
                      <td>簽名人數:</td>
                      <td>{item.signCount} 人</td>

                      <td>完成率:</td>
                      <td>
                        {item.totalCount > 0
                          ? _.min([
                              _.round(item.signCount / item.totalCount, 2) *
                                100,
                              100,
                            ])
                          : 0}
                        %
                      </td>
                    </tr>
                  </table>
                </div>

                {/* 簽名人員 record */}
                <Table
                  columns={columns}
                  dataSource={item.workerList}
                  pagination={false}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
