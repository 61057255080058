import React, { Fragment } from "react";

// Routers
import { renderRoutes } from "react-router-config";
import routes from "./route.js";

//語系 locale
import { ConfigProvider } from "antd";
import zhTW from "antd/lib/locale/zh_TW";
import moment from "moment";
import "moment/locale/zh-tw";

//Styles
import "./App.scss";

// 設定時間語系
moment.locale("zh-tw");

function App() {
  return (
    <ConfigProvider locale={zhTW}>
      <Fragment>{renderRoutes(routes)}</Fragment>
    </ConfigProvider>
  );
}

export default App;
